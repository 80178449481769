import React from 'react'
// noinspection ES6CheckImport
import PropTypes from 'prop-types'
import ListWithChecksItem from './list-with-checks-item'
import './list-with-checks.scss'

const Content = ({ list }) => {
    return (
        <div className="list-with-checks-items">
            {list.map(item => {
                return (
                    <ListWithChecksItem
                        key={item.title}
                        title={item.title}
                        description={item.description}
                    />
                )
            })}
        </div>
    )
}

Content.propTypes = {
    list: PropTypes.instanceOf(Array).isRequired,
}

export default Content

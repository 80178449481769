import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Slider from './slider'
import 'slick-carousel/slick/slick.css'
import './slider.scss'

const query = graphql`
    query {
        contentfulServicesPage {
            howWeBuildProductsTitle
            howWeBuildProductsSubtitle
            howWeBuildProductsItems {
                title
                description
            }
        }
    }
`

const Content = () => {
    const data = useStaticQuery(query)

    return (
        <section className="slider-section full-width">
            <div className="slider-section__limiter">
                <div className="heading-container align-left">
                    <h2>
                        {data.contentfulServicesPage.howWeBuildProductsTitle}
                    </h2>
                    <p>
                        {data.contentfulServicesPage.howWeBuildProductsSubtitle}
                    </p>
                </div>
            </div>
            <Slider
                list={data.contentfulServicesPage.howWeBuildProductsItems}
            />
        </section>
    )
}

export default Content

// noinspection ES6CheckImport
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import Slider from 'react-slick'
import SVG from 'react-inlinesvg'
import IcoArrow from '../../../assets/images/ico-arrow.svg'

class Content extends Component {
    constructor(props) {
        super(props)
        this.next = this.next.bind(this)
        this.prev = this.prev.bind(this)
        this.state = {
            currentIndex: 1,
        }
    }

    next() {
        this.slider.slickNext()
    }

    prev() {
        this.slider.slickPrev()
    }

    render() {
        const { list } = this.props
        const { currentIndex } = this.state
        const Icon = () => <SVG src={IcoArrow} className="svg-ico" />

        return (
            <div>
                <Slider
                    ref={c => {
                        this.slider = c
                    }}
                    centerMode
                    infinite={false}
                    slidesToShow={1}
                    slidesToScroll={1}
                    focusOnSelect
                    arrows={false}
                    variableWidth
                    speed={500}
                    afterChange={index => {
                        this.setState({
                            currentIndex: index + 1,
                        })
                    }}
                    responsive={[
                        {
                            breakpoint: 1441,
                            settings: {
                                centerMode: false,
                            },
                        },
                        {
                            breakpoint: 928,
                            settings: {
                                centerMode: true,
                            },
                        },
                        {
                            breakpoint: 812,
                            settings: {
                                centerMode: false,
                            },
                        },
                    ]}
                >
                    {list.map((item, index) => {
                        return (
                            <div className="slider-item" key={item.title}>
                                <div className="slider-item__header">
                                    <div className="slider-item__number">
                                        {index + 1}
                                    </div>
                                    <div className="h5">{item.title}</div>
                                </div>
                                <div className="slider-item__description">
                                    <p>{item.description}</p>
                                </div>
                            </div>
                        )
                    })}
                </Slider>
                <div className="slider-section__limiter">
                    <div className="slider-controls">
                        <div className="slider-arrows">
                            <button
                                type="button"
                                className="slider-arrow slider-arrow--prev"
                                onClick={this.prev}
                            >
                                <Icon />
                            </button>
                            <button
                                type="button"
                                className="slider-arrow slider-arrow--next"
                                onClick={this.next}
                            >
                                <Icon />
                            </button>
                        </div>
                        <div className="slider-counter">
                            {currentIndex} / {list.length}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

Content.propTypes = {
    list: PropTypes.instanceOf(Array).isRequired,
}

Content.defaultProps = {}

export default Content

import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import ListWithChecks from '../list-with-checks/list-with-checks'
import HeroImg from '../../assets/images/hero-how-we-work.png'

const query = graphql`
    query {
        contentfulServicesPage {
            howWeWorkTitle
            howWeWorkSubtitle
            howWeWorkItems {
                title
                description
            }
        }
    }
`

const Content = () => {
    const data = useStaticQuery(query)

    return (
        <section>
            <div className="content-narrow content-narrow--small">
                <h2 className="h1">
                    {data.contentfulServicesPage.howWeWorkTitle}
                </h2>
            </div>

            <div className="content-hero-image">
                <img src={HeroImg} alt="How we work" />
            </div>

            <div className="content-narrow">
                <ListWithChecks
                    list={data.contentfulServicesPage.howWeWorkItems}
                />
            </div>
        </section>
    )
}

export default Content

import PropTypes from 'prop-types'
import React from 'react'
import Layout from '../components/layout'
import WhatWeDo from '../components/services/what-we-do/what-we-do'
import HowWeBuildProducts from '../components/services/how-we-build-products/how-we-build-products'
import HowWeWork from '../components/services/how-we-work'
import Seo from '../components/seo/seo'

const Content = ({ location }) => {
    return (
        <Layout>
            <Seo
                title="Services - South Lane"
                desc="We offer a range of services for projects of all shapes and sizes. Working with us is simple, transparent and collaborative."
                pathname={location.pathname}
            />

            <WhatWeDo />
            <HowWeBuildProducts />
            <HowWeWork />
        </Layout>
    )
}

Content.propTypes = {
    location: PropTypes.shape({ pathname: PropTypes.string.isRequired })
        .isRequired,
}

export default Content

import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import './what-we-do.scss'
import WhatWeDoItem from './what-we-do-item'
import HeroImg from '../../../assets/images/hero-what-we-do.png'

const query = graphql`
    query {
        contentfulServicesPage {
            whatWeDoTitle
            whatWeDoSubtitle {
                whatWeDoSubtitle
            }
            whatWeDoItems {
                title
                description
                icon
            }
        }
    }
`

const Content = () => {
    const data = useStaticQuery(query)

    return (
        <section>
            <div className="content-narrow content-narrow--small">
                <h1>{data.contentfulServicesPage.whatWeDoTitle}</h1>
            </div>

            <div className="content-hero-image">
                <img src={HeroImg} alt="What we do" />
            </div>

            <div className="content-narrow">
                <div className="what-we-do-items">
                    {data.contentfulServicesPage.whatWeDoItems.map(item => {
                        return (
                            <WhatWeDoItem
                                key={item.title}
                                icon={item.icon}
                                title={item.title}
                                description={item.description}
                            />
                        )
                    })}
                </div>
            </div>
        </section>
    )
}

export default Content

import React from 'react'
// noinspection ES6CheckImport
import PropTypes from 'prop-types'
import SVG from 'react-inlinesvg'
import './what-we-do-item.scss'

const Content = ({ icon, title, description }) => {
    // eslint-disable-next-line
    const iconFIle = require(`../../../assets/images/ico-${icon}.svg`)

    const Icon = () => <SVG src={iconFIle} className="svg-ico" />

    return (
        <div className="what-we-do-item">
            <div className="what-we-do-item__header">
                <div className="what-we-do-item__icon">
                    <Icon />
                </div>
                <div className="h6">{title}</div>
            </div>
            <div className="what-we-do-item__description">
                <p>{description}</p>
            </div>
        </div>
    )
}

Content.propTypes = {
    icon: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
}

Content.defaultProps = {}

export default Content

// noinspection ES6CheckImport
import React from 'react'
import PropTypes from 'prop-types'

const Content = ({ title, description }) => {
    return (
        <div className="list-with-checks-item">
            <div className="list-with-checks-item__header">
                <i className="ico ico--check ico--success" />
                <div className="h6">{title}</div>
            </div>
            <div className="list-with-checks-item__description">
                <p className="p-l">{description}</p>
            </div>
        </div>
    )
}

Content.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
}

Content.defaultProps = {}

export default Content
